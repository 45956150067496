import {
  HiddenCost,
  NoObligation,
  PersonalisedService,
  ProtectPrivacy,
  TeamExperts,
  TimeStres,
} from "../../svgs/WhyDigital_Svgs";
import Internet from "../../images/ProductHeaderImages/BusinessInternet.jpeg";
import { Product_Quote_Routes } from "../Routes";

export const Keybenifits = [
  {
    id: 1,
    src: HiddenCost,
    title: "NBN",
    paragraph: "Give your business a stronger internet connection.",
  },
  {
    id: 2,
    src: TimeStres,
    title: "Fibre",
    paragraph: "Allow your team to get the job done from anywhere.",
  },
  {
    id: 3,
    src: NoObligation,
    title: "NBN Ethernet",
    paragraph: "Complete business operations with increased speed.",
  },
  {
    id: 4,
    src: ProtectPrivacy,
    title: "IP-Line",
    paragraph: "Increase accessibility to Cloud-based services.",
  },
  {
    id: 5,
    src: PersonalisedService,
    title: "Fibre1000",
    paragraph: "Maintain fast internet speeds with low lag times.",
  },
  {
    id: 6,
    src: TeamExperts,
    title: "IP VPN",
    paragraph: "Secure remote access to your company’s internet.",
  },
];

export const HeaderData = {
  src: Internet,
  subtitle: "Get The Internet Services Your Business Deserves",
  title: "Business Internet",
  paragraph: "Connect with Australia’s top internet providers now.",
  to: Product_Quote_Routes.BusinessInternet,
};

export const DescriptionContent = {
  title: "Why Do You Need a Strong Internet Connection?",
  paragraph:
    "No business can succeed without a strong internet connection.Increase productivity and decrease costs with efficient business internet services that you can trust.",
  to: Product_Quote_Routes.BusinessInternet,
  profitPoint1: "No more slow connections",
  profitPoint2: " Reliable access to all internet resources",
  profitPoint3: "Ability to work remotely",
  profitPoint4: "Happy and productive employees",
};

export const FAQS = [
  {
    title: "Does my business need a strong internet connection? ",
    answer:
      "Almost every business in the modern world now requires stable internet. While you might survive without it, the internet makes running a business easier.  ",
  },
  {
    title: "What is fibre-optic internet?",
    answer:
      "Fibre-optic internet is a type of broadband connection. It is categorised by swift speeds, which increase business productivity.  ",
  },
  {
    title: "What are the benefits of NBN?",
    answer:
      "NBN has many benefits, including stronger connections and faster service.   ",
  },
  {
    title: "How much does connecting to the internet cost?",
    answer:
      "Prices change depending on your requirements. Fill out our questionnaire and Digital Comparison will find you the best internet prices according to your needs.  ",
  },
  {
    title: "How long does it take to connect my business to the internet?",
    answer: "The time it takes depends on supplier.  ",
  },
  {
    title: "How does a strong internet connection save my business money?",
    answer:
      "Increased productivity leads to more sales and larger inflows. By using a leading supplier, you can also save on setup and maintenance costs.",
  },
  {
    title: "How can Digital Comparison get me connected to the internet?",
    answer:
      "We connect you with the top suppliers of business internet. All you have to do is compare the quotes until you find your ideal solution.",
  },
  {
    title: "Which business internet provider is the best?",
    answer:
      "All our supply partners are trusted industry leaders. The right one for you will depend on your goals, budget, and needs. ",
  },
];
